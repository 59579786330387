<template>
    <v-card outlined style="border-style: none">
        <v-img
            :src="image"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            class="white--text align-end"
            width="270px"
            height="208px"
        >
            <v-card-title
                class="ml-4 pl-1 py-0 mb-2"
                style="background-color: #c30013; opacity: 0.8"
                ><div
                    :class="
                        $vuetify.breakpoint.smAndUp
                            ? 'text-body-1'
                            : 'text-body-2'
                    "
                >
                    {{ gymName }}
                </div>
            </v-card-title>
        </v-img>
        <v-card-subtitle class="pa-0">{{ description }}</v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: true,
        },
        gymName: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped></style>
